import { html } from 'lit';
import cloneDeep from 'lodash-es/cloneDeep';
import MsgMixin from '../../common/MsgMixin';
import LoaderMixin from '../../common/LoaderMixin';
import Mix from '../../common/Mix';

export default function PromiseData(clz) {
  return class extends Mix(clz, LoaderMixin, MsgMixin) {
    set data(val) {
      this.msg = '';
      if (val && val.then) {
        // promise
        this.promiseLoader(
          val
            .then(data => {
              this.data = data;
            })
            .catch(error => {
              this.msg = html` <span class="error">${error}</span> `;
            }),
        );
      } else {
        super.data = cloneDeep(val) || [];
        if (super.data.length === 0) {
          this.msg = this.noDataMessage || 'No Data';
        }
      }
    }

    get data() {
      return super.data;
    }

    render() {
      return this._renderLoader() || this._renderMsg() || super.render();
    }
  };
}
