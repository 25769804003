import { css, html } from 'lit';

export default function MsgMixin(clz) {
  return class extends clz {
    static styles = css`
      .msg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .msg .error {
        color: red;
      }
    `;

    constructor() {
      super();
      this._msg = '';
    }

    _renderMsg() {
      if (this._msg) {
        return html` <div class="msg">${this._msg}</div> `;
      }
      return '';
    }

    set msg(msg) {
      this._msg = msg;
      this.requestUpdate();
    }
  };
}
