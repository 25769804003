/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import { responsiveMixin } from '@kisters/wcp-base/decorators';

// @ts-expect-error
@customElement('ki-wwp-switcher-bottom')
export default class KiWwpSwitcherBottom extends Mix(
  LitElement,
  responsiveMixin,
) {
  static styles = css`
    :host {
      display: flex;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      border-radius: 30px;
      font-size: 0;
      overflow: visible;
    }
    .tab {
      background: white;
      color: var(--theme-color-primary, #1d1d1b);
      display: flex;
      padding: 8px 10px;
      min-width: 40px;
      text-align: center;
      cursor: pointer;
      user-select: none;
      position: relative;
      font-size: 14px;
      line-height: 24px;
      align-items: center;
      justify-content: center;
    }

    ki-icon {
      margin-right: 5px;
      font-size: 1.3em;
    }
    div.tab.selected {
      color: var(--theme-text-color, #ffffff);
      background: linear-gradient(
        360deg,
        var(--theme-color-primary) 5px,
        rgb(255, 255, 255) 0%
      );
      font-weight: bold;
      transition: all 1s fade;
    }
    div.tab:hover {
      background-position: 10px;
      color: var(--theme-text-color, #ffffff);
      font-weight: bold;
    }

    .tab .label {
      font-size: 1.5em;
    }
    :host(.sm-screen) ki-icon {
      font-size: 1.5em;
      margin-right: 0;
    }
    :host(.sm-screen) .tab {
      min-width: initial;
      padding: 9px 10px;
    }
    :host(.sm-screen) .tab span.label {
      display: initial;
      font-size: 1.2em;
    }
  `;

  static get properties() {
    return {
      options: { type: Array },
      value: { type: String },
    };
  }

  get selected() {
    const preselected = this.options.filter(opt => opt.selected);
    return preselected.length ? preselected[0].value : this.options[0].value;
  }

  render() {
    if (!this.options.length) {
      return html``;
    }
    this.value = this.value || this.selected;
    // language=html
    return html`
      ${this.options.map(
        (option, i) => html`
          <div
            part="tabbutton"
            class="tab ${option.value === this.value ? 'selected' : ''} ${this
              .options.length -
              1 ===
            i
              ? 'last'
              : ''}"
            tabindex="0"
            @keydown="${e => {
              e.key === 'Enter' && this.switchTo(option);
            }}"
            @click="${() => {
              this.switchTo(option);
            }}"
          >
            <div class="badgesContainer">
              ${option?.badges?.map(
                badge =>
                  html`<div
                    title="${badge.label || ''}"
                    class="badge"
                    style="background: ${badge.bgcolor ||
                    'white'};color: ${badge.color}"
                  >
                    ${badge.count}
                  </div>`,
              )}
            </div>
            ${option.icon
              ? `<ki-icon icon="${option.icon || ''}"></ki-icon>`
              : ``}
            <span class="label">${option.label}</span>
          </div>
        `,
      )}
    `;
  }

  switchTo(option) {
    if (this.value !== option.value) {
      this.value = option.value;
      this.dispatchEvent(
        new CustomEvent('changed', {
          detail: { value: option.value, element: option },
        }),
      );
    }
  }
}
