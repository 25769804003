import { html } from 'lit';

export class Loader extends EventTarget {
  get loading() {
    return this._$loaderCount > 0;
  }

  _loaderCountChanged = new Event('loaderCountChanged');

  _$loaderCount = 0;

  promiseLoader(p) {
    this._$loaderCount += 1;
    this.dispatchEvent(this._loaderCountChanged);
    return p.finally(() => {
      this._$loaderCount -= 1;
      this.dispatchEvent(this._loaderCountChanged);
    });
  }

  // default loader
  _renderLoader() {
    if (this.loading) {
      return html`
        <style>
          .spiner-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 99;
          }
          @keyframes ldio {
            0% {
              transform: rotate(0deg);
            }
            50% {
              transform: rotate(180deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          .ldio div {
            position: absolute;
            animation: ldio 1.01s linear infinite;
            width: 78px;
            height: 78px;
            top: 11px;
            left: 11px;
            border-radius: 50%;
            box-shadow: 0 3.8000000000000003px 0 0 #1477eb;
            transform-origin: 39px 40.9px;
          }
          .loadingio-spinner-eclipse {
            width: 34px;
            height: 34px;
            display: inline-block;
            overflow: hidden;
            background: none;
          }
          .ldio {
            width: 100%;
            height: 100%;
            position: relative;
            transform: translateZ(0) scale(0.34);
            backface-visibility: hidden;
            transform-origin: 0 0;
          }
          .ldio div {
            box-sizing: content-box;
          }
        </style>
        <div class="spiner-wrapper">
          <div class="loadingio-spinner-eclipse">
            <div class="ldio">
              <div></div>
            </div>
          </div>
        </div>
      `;
    }
    return '';
  }
}
