/* eslint-disable wc/no-self-class */
/* eslint-disable max-classes-per-file */
import { UpdatingElement, html, css } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';

@customElement('ki-list-item')
export default class KiListItem extends UpdatingElement {
  // language=CSS
  static styles = css`
    ki-list-item {
      display: block;
      position: relative;
      padding: 12px;
    }

    ki-list-item:hover,
    ki-list-item:focus {
      color: var(--theme-color, #0056a0);
      background-color: #eeeeee;
    }

    ki-list-item.selected {
      background-color: var(--theme-color, #0056a0);
      color: var(--theme-text-color, white);
    }
  `;

  @property({ type: Boolean })
  selected: boolean = false;

  @property({ type: String })
  _indent: string | undefined;

  constructor() {
    super();
    this.addEventListener('keydown', e => {
      switch (e.key) {
        case 'Enter':
          this.dispatchEvent(new CustomEvent('click'));
          break;
        case 'ArrowUp':
        case 'ArrowLeft':
          // @ts-expect-error
          this._getPrev()?.focus();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          // @ts-expect-error
          this._getNext()?.focus();
          break;
        default:
      }
    });
  }

  get itemNode(): HTMLElement {
    return this;
  }

  _getNext() {
    let next = this.nextSibling;
    // @ts-expect-error
    while (!/^KI-LIST-ITEM/.test(next?.tagName)) {
      next = next?.nextSibling ?? null;
    }
    return next;
  }

  _getPrev() {
    let next = this.previousSibling;

    // @ts-expect-error
    while (!/^KI-LIST-ITEM/.test(next?.tagName)) {
      next = next?.previousSibling ?? null;
    }
    return next;
  }

  _updateSelectedState() {
    this.setAttribute('tabindex', this.selected ? '0' : '-1');
    this.classList.toggle('selected', !!this.selected);
  }

  connectedCallback() {
    // @ts-expect-error
    if (super.connectedCallback) super.connectedCallback();
    let level = 0;
    let node: HTMLElement | null = this;
    while (node && node.tagName !== 'KI-LIST') {
      if (/^KI-LIST/.test(node.tagName)) level += 1;
      node = node.parentElement;
    }
    this.itemNode.style[
      'padding-left'
    ] = `calc( var(--ki-list-indent) * ${level})`;
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html` <slot></slot>`;
  }
}
