const hclocales = {
  es: {
    loading: 'Cargando...',
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    weekdays: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
    shortMonths: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
    exportButtonTitle: 'Exportar',
    printButtonTitle: 'Importar',
    rangeSelectorFrom: 'Desde',
    rangeSelectorTo: 'Hasta',
    rangeSelectorZoom: 'Período',
    downloadPNG: 'Descargar imagen PNG',
    downloadJPEG: 'Descargar imagen JPEG',
    downloadPDF: 'Descargar imagen PDF',
    downloadSVG: 'Descargar imagen SVG',
    printChart: 'Imprimir',
    resetZoom: 'Reiniciar zoom',
    resetZoomTitle: 'Reiniciar zoom',
    thousandsSep: ',',
    decimalPoint: '.',
  },
  pt: {
    loading: 'Aguarde...',
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    weekdays: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Abr',
      'Maio',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    exportButtonTitle: 'Exportar',
    printButtonTitle: 'Imprimir',
    rangeSelectorFrom: 'De',
    rangeSelectorTo: 'Até',
    rangeSelectorZoom: 'Periodo',
    downloadPNG: 'Download imagem PNG',
    downloadJPEG: 'Download imagem JPEG',
    downloadPDF: 'Download documento PDF',
    downloadSVG: 'Download imagem SVG',
    // resetZoom: "Reset",
    // resetZoomTitle: "Reset,
    // thousandsSep: ".",
    // decimalPoint: ','
  },
  ru: {
    loading: 'Загрузка...',
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    weekdays: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],
    shortMonths: [
      'Янв',
      'Фев',
      'Март',
      'Апр',
      'Май',
      'Июнь',
      'Июль',
      'Авг',
      'Сент',
      'Окт',
      'Нояб',
      'Дек',
    ],
    exportButtonTitle: 'Экспорт',
    printButtonTitle: 'Печать',
    rangeSelectorFrom: 'С',
    rangeSelectorTo: 'По',
    rangeSelectorZoom: 'Период',
    downloadPNG: 'Скачать PNG',
    downloadJPEG: 'Скачать JPEG',
    downloadPDF: 'Скачать PDF',
    downloadSVG: 'Скачать SVG',
    printChart: 'Напечатать график',
  },
  de: {
    decimalPoint: ',',
    thousandsSep: '.',
    loading: 'Daten werden geladen...',
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    weekdays: [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ],
    exportButtonTitle: 'Exportieren',
    printButtonTitle: 'Drucken',
    rangeSelectorFrom: 'Von',
    rangeSelectorTo: 'Bis',
    rangeSelectorZoom: 'Zeitraum',
    downloadPNG: 'Download als PNG-Bild',
    downloadJPEG: 'Download als JPEG-Bild',
    downloadPDF: 'Download als PDF-Dokument',
    downloadSVG: 'Download als SVG-Bild',
    resetZoom: 'Zoom zurücksetzen',
    resetZoomTitle: 'Zoom zurücksetzen',
    noData: 'keine Daten vorhanden für den ausgewählten Zeitbereich',
  },
  nl: {
    loading: 'Wordt geladen...',
    months: [
      'januari',
      'februari',
      'maart',
      'april',
      'mei',
      'juni',
      'juli',
      'augustus',
      'september',
      'oktober',
      'november',
      'december',
    ],
    weekdays: [
      'zondag',
      'maandag',
      'dinsdag',
      'woensdag',
      'donderdag',
      'vrijdag',
      'zaterdag',
    ],
    shortMonths: [
      'jan',
      'feb',
      'maa',
      'apr',
      'mei',
      'jun',
      'jul',
      'aug',
      'sep',
      'okt',
      'nov',
      'dec',
    ],
    exportButtonTitle: 'Exporteren',
    printButtonTitle: 'Printen',
    rangeSelectorFrom: 'Vanaf',
    rangeSelectorTo: 'Tot',
    rangeSelectorZoom: 'Periode',
    downloadPNG: 'Download als PNG',
    downloadJPEG: 'Download als JPEG',
    downloadPDF: 'Download als PDF',
    downloadSVG: 'Download als SVG',
    resetZoom: 'Reset',
    resetZoomTitle: 'Reset',
    thousandsSep: '.',
    decimalPoint: ',',
    exitFullscreen: 'Vollbild Modues',
    viewFullscreen: 'Vollbild Modus verlassen',
  },
  tr: {
    loading: 'Yükleniyor...',
    months: [
      'Ocak',
      'Şubat',
      'Mart',
      'Nisan',
      'Mayıs',
      'Haziran',
      'Temmuz',
      'Ağustos',
      'Eylül',
      'Ekim',
      'Kasım',
      'Aralık',
    ],
    weekdays: [
      'Pazar',
      'Pazartesi',
      'Salı',
      'Çarşamba',
      'Perşembe',
      'Cuma',
      'Cumartesi',
    ],
    shortMonths: [
      'Ocak',
      'Şubat',
      'Mart',
      'Nisan',
      'Mayıs',
      'Haziran',
      'Temmuz',
      'Ağustos',
      'Eylül',
      'Ekim',
      'Kasım',
      'Aralık',
    ],
    exportButtonTitle: 'Dışarı Aktar',
    printButtonTitle: 'Yazdır',
    rangeSelectorFrom: 'Başlangış',
    rangeSelectorTo: 'Bitiş',
    rangeSelectorZoom: 'Periyot',
    downloadPNG: 'PNG Olarak indir',
    downloadJPEG: 'JPEG olarak indir',
    downloadPDF: 'PDF olarak indir',
    downloadSVG: 'SVG olarak indir',
    resetZoom: ['Yakınlaşmayı Sıfırla'],
    resetZoomTitle: ['Yakınlaşmayı Sıfırla'],
    printChart: ['Yazdır'],
    thousandsSep: '.',
    decimalPoint: ',',
  },
  it: {
    loading: 'Sto caricando...',
    months: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
    weekdays: [
      'Domenica',
      'Lunedì',
      'Martedì',
      'Mercoledì',
      'Venerdì',
      'Sabato',
      'Domenica',
    ],
    shortMonths: [
      'Gen',
      'Feb',
      'Mar',
      'Apr',
      'Mag',
      'Giu',
      'Lugl',
      'Ago',
      'Set',
      'Ott',
      'Nov',
      'Dic',
    ],
    exportButtonTitle: 'Esporta',
    printButtonTitle: 'Importa',
    rangeSelectorFrom: 'Da',
    rangeSelectorTo: 'A',
    rangeSelectorZoom: 'Periodo',
    downloadPNG: 'Download immagine PNG',
    downloadJPEG: 'Download immagine JPEG',
    downloadPDF: 'Download documento PDF',
    downloadSVG: 'Download immagine SVG',
    printChart: 'Stampa grafico',
    thousandsSep: '.',
    decimalPoint: ',',
  },
  fr: {
    loading: 'Chargement...',
    months: [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ],
    weekdays: [
      'dimanche',
      'lundi',
      'mardi',
      'mercredi',
      'jeudi',
      'vendredi',
      'samedi',
    ],
    shortMonths: [
      'jan',
      'fév',
      'mar',
      'avr',
      'mai',
      'juin',
      'juil',
      'aoû',
      'sep',
      'oct',
      'nov',
      'déc',
    ],
    exportButtonTitle: 'Exporter',
    printButtonTitle: 'Imprimer',
    printChart: 'Imprimer',
    rangeSelectorFrom: 'Du',
    rangeSelectorTo: 'au',
    rangeSelectorZoom: 'Période',
    downloadPNG: 'Télécharger en PNG',
    downloadJPEG: 'Télécharger en JPEG',
    downloadPDF: 'Télécharger en PDF',
    downloadSVG: 'Télécharger en SVG',
    resetZoom: 'Réinitialiser le zoom',
    resetZoomTitle: 'Réinitialiser le zoom',
    thousandsSep: ' ',
    decimalPoint: ',',
    contextButtonTitle: 'Menu',
    exitFullscreen: "Quitter l'affichage plein écran",
    viewFullscreen: 'Affichage plein écran',
    noData: 'Aucune donnée disponible pour la plage horaire sélectionnée',
  },
  'fr-BE': {
    loading: 'Chargement...',
    months: [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ],
    weekdays: [
      'dimanche',
      'lundi',
      'mardi',
      'mercredi',
      'jeudi',
      'vendredi',
      'samedi',
    ],
    shortMonths: [
      'jan',
      'fév',
      'mar',
      'avr',
      'mai',
      'juin',
      'juil',
      'aoû',
      'sep',
      'oct',
      'nov',
      'déc',
    ],
    exportButtonTitle: 'Exporter',
    printButtonTitle: 'Imprimer',
    printChart: 'Imprimer',
    rangeSelectorFrom: 'Du',
    rangeSelectorTo: 'au',
    rangeSelectorZoom: 'Période',
    downloadPNG: 'Télécharger en PNG',
    downloadJPEG: 'Télécharger en JPEG',
    downloadPDF: 'Télécharger en PDF',
    downloadSVG: 'Télécharger en SVG',
    resetZoom: 'Réinitialiser le zoom',
    resetZoomTitle: 'Réinitialiser le zoom',
    thousandsSep: ' ',
    decimalPoint: ',',
    contextButtonTitle: 'Menu',
    exitFullscreen: "Quitter l'affichage plein écran",
    viewFullscreen: 'Affichage plein écran',
  },
  pl: {
    loading: 'Ładowanie...',
    months: [
      'Styczeń',
      'Luty',
      'Marzec',
      'Kwiecień',
      'Maj',
      'Czerwiec',
      'Lipiec',
      'Sierpień',
      'Wrzesień',
      'Październik',
      'Listopad',
      'Grudzień',
    ],
    weekdays: [
      'Niedziela',
      'Poniedziałek',
      'Wtorek',
      'Środa',
      'Czwartek',
      'Piątek',
      'Sobota',
    ],
    shortMonths: [
      'Sty',
      'Lut',
      'Mar',
      'Kwi',
      'Maj',
      'Cze',
      'Lip',
      'Sie',
      'Wrz',
      'Paź',
      'Lis',
      'Gru',
    ],
    exportButtonTitle: 'Export',
    printButtonTitle: 'Drukowanie',
    rangeSelectorFrom: 'Od',
    rangeSelectorTo: 'Do',
    rangeSelectorZoom: 'Przybliżenie',
    downloadPNG: 'Pobierz obraz PNG',
    downloadJPEG: 'Pobierz obraz JPEG',
    downloadPDF: 'Pobierz dokument PDF',
    downloadSVG: 'Pobierz obraz SVG',
    thousandsSep: ' ',
    decimalPoint: ',',
    // resetZoom: "Reset",
    // resetZoomTitle: "Reset,
  },
  zh: {
    loading: '加载中...',
    months: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    shortMonths: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    weekdays: [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六',
    ],
    exportButtonTitle: '导出',
    printButtonTitle: '打印',
    rangeSelectorFrom: '从',
    rangeSelectorTo: '到',
    rangeSelectorZoom: '缩放',
    downloadPNG: '下载PNG格式',
    downloadJPEG: '下载JPEG格式',
    downloadPDF: '下载PDF格式',
    downloadSVG: '下载SVG格式',
  },
};
export default hclocales;
