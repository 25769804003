import { css, html, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement } from 'lit/decorators.js';
import debounce from 'lodash-es/debounce';
import Mix from '../../common/Mix';
import '../ki-icon/ki-icon';
import nls from '../../locales/index';
import { i18nMixin } from '../../decorators/i18n';

// @ts-expect-error
@customElement('ki-searchbox')
export default class KiSearchbox extends Mix(LitElement, [i18nMixin, { nls }]) {
  static styles = [
    css`
      :host {
        width: 100%;
        border: 1px solid transparent;
        transition: all linear 0.7s;
        box-sizing: border-box;
        padding: 6px;
        height: 40px;
        color: grey;
        fill: grey;
        border-radius: 30px;
        line-height: 26px;
        background: white;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
      }

      .input-wrapper {
        flex: 1;
        position: relative;
        line-height: 26px;
        height: 26px;
        font-size: 14px;
      }

      input::placeholder {
        font-style: italic;
      }

      input,
      input::placeholder {
        width: 100%;
        font-size: inherit;
        margin-top: 5px;
        border: none;
        box-shadow: none;
        outline: none;
        padding: 0;
        line-height: 21px;
        color: inherit;
        background: inherit;
        font-family: inherit;
        --_lumo-text-field-overflow-mask-image: linear-gradient(
          to left,
          transparent,
          #000 1.25em
        );
        -webkit-mask-image: var(--_lumo-text-field-overflow-mask-image);
        mask-image: var(--_lumo-text-field-overflow-mask-image);
      }

      ki-icon {
        text-align: center;
        padding: 2px;
        width: 26px;
        height: 26px;
        border-radius: 20px;
        flex: 0 0 26px;
      }

      .clear-btn {
        fill: rgb(126, 126, 126);
        position: absolute;
        right: 0;
        top: -6px;
        cursor: pointer;
        width: 12px;
      }

      ki-icon.btn {
        cursor: pointer;
      }

      ki-icon.btn:hover {
        fill: #4a4a49;
      }

      ki-icon.btn.actived {
        fill: white;
        background: var(--theme-color, #0056a0);
      }

      .hide {
        display: none;
      }
    `,
  ];

  constructor() {
    super();
    this.fieldList = [];
    this.value = '';
    this.emitSearch = debounce(() => {
      this.dispatchEvent(
        new CustomEvent('search', { detail: { value: this.value } }),
      );
    }, 200);
  }

  static get properties() {
    return {
      value: { type: String },
      placeholder: { type: String },
      fieldList: { type: Array },
    };
  }

  render() {
    // language=html
    return html`
      <ki-icon
        class="btn"
        id="search-btn"
        part="ki-searchbox-eyeglass"
        title="${this.placeholder || this.i18n.t('search')}"
        aria-label="${this.i18n.t('search')}"
        icon="ki ki-search"
      ></ki-icon>
      <div class="input-wrapper">
        <input
          id="input"
          type="text"
          part="ki-searchbox-input"
          @keyup="${this._handleChanged}"
          .value="${this.value}"
          placeholder="${this.placeholder || this.i18n.t('search')}"
        />
        <ki-icon
          class="clear-btn ${this.value ? '' : 'hide'}"
          part="ki-searchbox-clear"
          @click="${this.resetValue}"
          icon="ki ki-times"
          aria-label="${this.i18n.t('clear')}"
          title="${this.i18n.t('clear')}"
          id="clear-btn"
        ></ki-icon>
      </div>
      <slot name="actions"> </slot>
    `;
  }

  _handleChanged() {
    const input = this.renderRoot.querySelector('#input');
    this.value = input.value;
    this.dispatchEvent(
      new CustomEvent('change', { detail: { value: this.value } }),
    );
    this.emitSearch(); // debounced
  }

  resetValue() {
    this.value = '';
    this.renderRoot.querySelector('#input').value = '';
    this._handleChanged();
  }
}
