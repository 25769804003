import { Router } from '@vaadin/router';
import { BehaviorSubject } from 'rxjs';

const defaultUrl = '/';

let _searchString = '';

let _lastParamsString = '';

let _urlParams = new URLSearchParams(_searchString);

const _getUrlParams = (): URLSearchParams => {
  if (_lastParamsString !== _searchString) {
    _urlParams = new URLSearchParams(_searchString);
    _lastParamsString = _searchString;
  }

  return _urlParams;
};

const _rewriteHashedSearchParams = (newString: string): void => {
  const path = window.location.hash.split('?')[0];
  const searchString = `${
    newString && newString.length > 0 ? `?${newString}` : ''
  }`;
  window.location.hash = `${path}${searchString}`;
};

export const searchParamsChangedSubject = new BehaviorSubject(true);

class HashRouter extends Router {
  /* eslint-disable class-methods-use-this */
  /* eslint-disable no-unused-vars */
  __updateBrowserHistory({ pathname, search }) {
    _searchString = search || '';
    const pathWithSearchParam = `${pathname}${_searchString}`;
    if (window.location.hash.length === 0) {
      window.location.hash = `#${defaultUrl}`;
    } else if (window.location.hash.substring(1) !== pathWithSearchParam) {
      window.location.hash = `#${pathWithSearchParam}`;
    }
    searchParamsChangedSubject.next(true);
  }

  navigationChange(newRoute, oldRoute) {
    console.log(newRoute, oldRoute);
  }

  /* eslint-enable class-methods-use-this */
  /* eslint-enable no-unused-vars */
  async __resolveRoute(context) {
    if (context.hash && context.hash.length > 0) {
      Router.go(context.hash.substring(1));
      return null;
    }
    // @ts-expect-error
    return super.__resolveRoute(context);
  }
}

function globalHashChangeHandler(event: any): void {
  if (event.newURL.split('?')[0] !== event.oldURL.split('?')[0]) {
    const pathname =
      event.newURL.indexOf('#') > -1
        ? event.newURL.substring(event.newURL.indexOf('#') + 1)
        : '/';

    Router.go(pathname);
  }
}

const HASHCHANGE = {
  activate() {
    window.addEventListener('hashchange', globalHashChangeHandler, false);
  },

  inactivate() {
    window.removeEventListener('hashchange', globalHashChangeHandler, false);
  },
};

// @ts-expect-error
Router.NavigationTrigger = [HASHCHANGE];

let router;

const getSearchParams = (): URLSearchParams => {
  if (router?.location.searchParams) {
    return router?.location.searchParams;
  }
  return _getUrlParams();
};

/**
 * Initializes the router and subscribes to window events. Call unsubscribe when router is no longer needed.
 * @param {string} baseUrl baseurl. Can be the base url of the application ("import.meta.env.BASE_URL" for a vite app) or the base url + url for a sub view if this router is a nested one.
 */
export const initRouter = (baseUrl: string): void => {
  router = new HashRouter(null, { baseUrl });
  router.subscribe();
};

/**
 * @param {Array<Object>} routes The vaid routes for this router. See https://vaadin.github.io/router/vaadin-router/#/classes/Router#method-setRoutes
 */
export const setRoutes = (routes: Array<any>): void => {
  router.setRoutes([
    // Redirect to URL without trailing slash
    {
      path: '(.+)/',
      action: (context, commands) => {
        const newPath = context.pathname.slice(0, -1);
        return commands.redirect(newPath);
      },
    },
    ...routes,
    // Remove the following section to deactivate defaultUrl
    {
      path: '(.*)',
      action: (context, commands) =>
        commands.redirect(context.path.split('/').slice(0, -1).join('/')),
    },
  ]);
};

/**
 * Attaches router to a DOM Element in which the view gets rendered
 * @param {?Node} outlet The (DOM) Element where the components will be rendered to
 */
export const attachRouter = (outlet: Element): void => {
  router.setOutlet(outlet);
};

/**
 * @returns The current location Object of the Vaadin Router.
 */
export const getCurrentLocation = (): any => router.location;

/**
 * @param {string} name The name of the route you want to get
 * @param {Object} params Params. See https://vaadin.github.io/router/vaadin-router/#/classes/Router
 * @returns The url leading to the specified component
 */
export const urlForName = (name: string, params: any): string =>
  router.urlForName(name, params);

/**
 * @returns Returns the String of all Search Params from the URL
 */
export const getCurrentPath = (): string => router.location.pathname;

/**
 * @returns Returns the String of all Search Params from the URL
 */
export const getSearchParamString = (): string => _searchString;

/**
 * Gets a single value of the given param.
 * @param {string} param The parameter you're looking for.
 * @returns Returns the value of the prameter
 */
export const getSearchParam = (param: string): string | null =>
  getSearchParams().get(param);

/**
 * Gets an array with all values of the given param.
 * @param {string} param The parameter you're looking for.
 * @returns Returns the value of the prameter
 */
export const getAllSearchParams = (param: string): Array<string> =>
  getSearchParams().getAll(param);

/**
 * @param {string} param The parameter you want to set.
 * @param {string} value The value to set.
 */
export const setSearchParam = (param: string, value: string): void => {
  const urlParams = _getUrlParams();
  if (!value || (Array.isArray(value) && value.length === 0)) {
    urlParams.delete(param);
  } else if (Array.isArray(value)) {
    urlParams.set(param, value[0]);
    const [, ...rest] = value;
    rest.forEach(val => urlParams.append(param, val));
  } else {
    urlParams.set(param, value);
  }

  const paramString = urlParams.toString();

  if (paramString !== _searchString) {
    _searchString = paramString;
    _lastParamsString = paramString;

    _rewriteHashedSearchParams(paramString);
  }
};

/**
 * Gets the options and route params for the location.
 * You can call this method inside 'onAfterEnter' in your component.
 * @param {Object} location Current location Object of Vaadin Router.
 * @param {Array} paramNames Array of parameters to load.
 * @returns Returns the value of the prameter
 */
export const getRouteOptionsAndParams = (
  location: any,
  paramNames: Array<string>,
): any => {
  const { options } = location.route;
  const { params } = location;

  const ret = { options };

  paramNames.forEach(paramName => {
    ret[paramName] = params[paramName];
  });

  return ret;
};

/**
 * @param {string} path The path you want to route to
 * @param {boolean} conserveSearchParams Whether to preserve the search parameters.
 * @returns Returns a boolean without waiting until the navigation is complete. Returns true if at least one Router has handled the navigation (was subscribed and had baseUrl matching the path argument), otherwise returns false.
 */
export const navigateTo = (
  path: string,
  conserveSearchParams: boolean = false,
): boolean => {
  let navTo = path;
  if (conserveSearchParams) {
    const searchParamString = getSearchParamString();
    const addString = `${
      searchParamString.length > 0 && !searchParamString.startsWith('?')
        ? '?'
        : ''
    }${searchParamString}`;
    navTo = `${navTo}${addString}`;
  }
  router.navigationChange(
    navTo.split('?')[0],
    router.location.pathname.split('?')[0],
  );
  return Router.go(navTo);
};

/**
 * Unregisters the router from window events
 */
export const unregisterRouter = (): void => {
  router.unsubscribe();
};
