import { customElement } from 'lit/decorators.js';
import { i18nMixin } from '../../decorators/i18n';
import nls from '../../locales/index';
import BaseTable from './BaseTable';
import PromiseData from './PromiseData';
import LazyRendering from './LazyRendering';
import SortAble from './SortAble';
import Mix from '../../common/Mix';
import ColumnHider from './ColumnHider';
import ColumnFormatter from './ColumnFormatter';
import Selection from './Selection';
import Export from './Export';

// for inherit setter getter need define both
// @ts-expect-error
@customElement('ki-table')
export default class KiTable extends Mix(
  BaseTable,
  [i18nMixin, { nls }],
  LazyRendering,
  Selection,
  PromiseData,
  SortAble,
  ColumnHider,
  ColumnFormatter,
  Export,
) {}
