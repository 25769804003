import { css, html, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';
import './ki-searchbox';
import './ki-search-query-action';
import '../ki-list/ki-list';
import '../ki-list/ki-list-item';

@customElement('ki-queryable-searchbox')
export default class KiQueryableSearchbox extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      display: block;
      position: relative;
    }
    #suggestion-popup {
      top: 100%;
      left: 0;
      width: 100%;
    }
  `;

  @property({ type: String })
  content = '';

  @property({ type: Array })
  fieldList: Array<any> = [];

  @property({ type: Array })
  suggestion: any;

  render() {
    // language=html
    return html`
      <ki-searchbox
        id="searchbox"
        .value="${this.content}"
        @search="${e => {
          this.content = e.detail.value;
        }}"
      >
        <div slot="actions">
          <ki-search-query-action
            @query="${this.onSearch}"
            @suggestion="${this._onSuggestion}"
            .fieldList="${this.fieldList}"
            .content="${this.content}"
          ></ki-search-query-action>
        </div>
      </ki-searchbox>
      <ki-popup id="suggestion-popup" ?visible="${!!this.suggestion}">
        <ki-list
          >${this.suggestion &&
          this.suggestion.options.map(
            h =>
              html`
                <ki-list-item
                  @click="${() =>
                    this._applySuggestion(this.suggestion.location, h)}"
                  >${h}</ki-list-item
                >
              `,
          )}</ki-list
        >
      </ki-popup>
    `;
  }

  _onSuggestion(e) {
    this.suggestion = e.detail.suggestion;
  }

  _applySuggestion(location, text) {
    this.content = this.content.substr(0, location.start.offset) + text;
  }

  onSearch(e) {
    this.dispatchEvent(
      new CustomEvent('search', { detail: { query: e.detail.query } }),
    );
  }
}
