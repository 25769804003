// eslint-disable-next-line max-classes-per-file
import { customElement, property } from 'lit/decorators.js';
import { find } from 'lodash-es';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import { init, getCurrentApi } from '@kisters/wiski-web/api';
import { initConfig } from '@kisters/wcp-base/app';
import KiWwpGraph from './ki-wwp-graph';

@customElement('ki-wwp-graph-ext')
export class KiWwpGraphExt extends KiWwpGraph {
  @property({ type: String })
  key;

  @property({ type: String })
  stationId;

  @property({ type: String })
  dataPath;

  favButton = false;

  firstUpdated() {
    this.numberFormatter = new Intl.NumberFormat(
      this.i18n.language,
      this.numberFormat || {},
    );
    this.initAppConfig();
    this.refreshInterval = setInterval(() => {
      this.fetchData();
    }, 300000);
  }

  async initAppConfig() {
    this.appcfg = await initConfig('./config/app.json', []);
    init({ dataPath: this.appcfg.apiPath });
    this.api = getCurrentApi();
    this.fetchData();
  }

  async fetchData() {
    dayjs.locale(this.i18n.language);
    const stationDetailComp = find(this.appcfg.route[0].children, {
      component: 'wwp-station-detail',
    });
    const configItem = find(stationDetailComp.children, { path: this.key });
    if (configItem) {
      Object.assign(this, configItem.options);
    }
    this._selectedPeriod = this.period;
    this.imageShare = false;
    this.tableOpen = false;
    super.fetchData();
  }
}
