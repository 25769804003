import dayjs from '@kisters/wcp-base/common/dayjsext';
import { filter, first } from 'lodash-es';

export class GraphHelper {
  public static transformForecast(
    data: Array<[string, number]>,
  ): Array<[string, number]> {
    return data.map(i => [dayjs(i[0]).valueOf(), i[1]]);
  }

  /** Check if ranges contains at least one matching element */
  public static rangeObjectContains(files: Object, filename: string): boolean {
    if (files[filename] || (Object.keys(files), k => k.includes(filename)))
      return true;
    return false;
  }

  /** Return a matching range element
   * @remark Always the returns the first matching element
   * Clarifiy the filename as much as possible, e.g. "level/year.json" instead of "year.json" to avoid unwanted matches
   */
  public static getFile(files: Object, rangeConfig: Object): Array<Object> {
    let result =
      files[rangeConfig.data] ||
      files[
        first(filter(Object.keys(files), k => k.includes(rangeConfig.data)))
      ];

    /** Filter for provided setting in rangeConfig */
    if (rangeConfig.ts_shortname) {
      result = filter(result, ts =>
        rangeConfig.ts_shortname.includes(ts.ts_shortname),
      );
    }
    return result;
  }
}
