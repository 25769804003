import { html, css, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';
import './ki-tree-node';

const SCROLL_STEP = 50; // for performance

@customElement('ki-tree')
export default class KiTree extends LitElement {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
      display: block;
      overflow: auto;
    }
    .group {
      overflow: hidden;
    }
  `;

  _data: any = [];

  @property({ type: Object })
  get data() {
    return this._data;
  }

  set data(val) {
    this._data = val;
    this.requestUpdate();
  }

  @property({ type: Number })
  _tailIndex = SCROLL_STEP;

  @property({ type: Boolean })
  multiple;

  @property({ type: Number })
  indent = 20; // default 20px;

  @property({ type: Boolean })
  hideRoot;

  value: Array<any> = [];

  expand = true;

  handleLabelClick;

  render() {
    return html`
      <div
        @itemSelected="${this.handleItemSelected}"
        @scroll="${this._loadMore}"
        @labelClicked="${this.handleLabelClick}"
      >
        <ki-tree-node
          class="${this.hideRoot ? 'hideRoot' : ''}"
          icon="${this.data.icon}"
          label="${this.data.label}"
          ?expand="${this.expand}"
          .paddingLeft="${this.indent}"
        >
          ${this.data.children &&
          this.data.children
            .slice(0, Math.min(this._tailIndex, this.data.children.length))
            .map(child => this._renderChild(child, this.indent * 2))}
        </ki-tree-node>
      </div>
    `;
  }

  _loadMore() {
    const scroller = this.renderRoot.querySelector('.tree');
    if (scroller && scroller.scrollTop > scroller.scrollHeight / 2) {
      if (this._tailIndex < this.data.children.length) {
        this._tailIndex = Math.min(
          this._tailIndex + SCROLL_STEP,
          this.data.children.length,
        );
      }
    }
  }

  _renderChild(child, paddingLeft) {
    if (child && child.children && child.children.length > 0) {
      return html`
        <div class="group">
          <ki-tree-node
            .icon="${child.icon}"
            .label="${child.label}"
            .value="${child.value}"
            .expand="${child.expand ||
            child.children.some(c => this.value.indexOf(c.value) >= 0)}"
            .paddingLeft="${paddingLeft}"
          >
            ${child.children.map(grandChild =>
              this._renderChild(grandChild, paddingLeft + this.indent),
            )}
          </ki-tree-node>
        </div>
      `;
    }

    return html`
      <ki-tree-node
        .icon="${child.icon}"
        .label="${child.label}"
        .value="${child.value}"
        isLeaf
        .selectedItem="${this.value}"
        .paddingLeft="${paddingLeft}"
      ></ki-tree-node>
    `;
  }

  handleItemSelected(event) {
    // console.log(event);
    const currentValue = event.detail.value;
    event.stopPropagation();
    const index = this.value && this.value.indexOf(currentValue);
    if (this.multiple) {
      if (index !== -1) {
        this.value = [
          ...this.value.slice(0, index),
          ...this.value.slice(index + 1),
        ];
      } else {
        this.value = [...this.value, currentValue];
      }
    } else {
      const { length } = this.value;
      if (length === 0 || index === -1) {
        this.value = [currentValue];
      } else {
        this.value = [];
      }
    }
    console.log('selected items:', this.value);
    this.requestUpdate();

    this.dispatchEvent(
      new CustomEvent('itemSelected', {
        detail: { value: this.value },
        bubbles: true,
        composed: true,
      }),
    );
  }

  // TODO deprecated
  reset() {
    this.value = [];
    this.requestUpdate();
  }
}
