import { LitElement, html } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';
import { navigateTo } from './routing/ki-router';

@customElement('ki-link')
export default class KiLink extends LitElement {
  @property({ type: String })
  href: string = '';

  @property({ type: String })
  label: string = '';

  constructor() {
    super();
    this.addEventListener('click', () => {
      navigateTo(this.href);
    });
  }

  render() {
    // language=html
    return html`${this.label}`;
  }
}
