import { ReplaySubject } from 'rxjs';

export const currentTheme = new ReplaySubject<boolean>(1);

let darkTheme: boolean | undefined;

export const updateTheme = () => {
  if (darkTheme !== undefined) {
    currentTheme.next(darkTheme);
  }
};

const setDarkTheme = (newTheme: boolean, dontUpdate: boolean = false) => {
  darkTheme = newTheme;
  if (!dontUpdate) {
    updateTheme();
  }
};

export const init = () => {
  setDarkTheme(window.matchMedia('(prefers-color-scheme: dark)').matches);
};

export const isDarkTheme = (): boolean => {
  if (darkTheme === undefined) {
    throw new Error('Theme Service needs to be initialized first.');
  }

  return darkTheme;
};

export const toggleTheme = (dontUpdate: boolean = false): boolean => {
  const newValue = !darkTheme;
  setDarkTheme(newValue, dontUpdate);
  return newValue;
};

export const activateLightTheme = () => {
  setDarkTheme(false);
};

export const activateDarkTheme = () => {
  setDarkTheme(true);
};
