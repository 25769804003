/* eslint-disable func-names */
import { defaultConverter } from 'lit';
import getPropertyDescriptor from './helper/getPropertyDescriptor';

const globalLocalStorage = window.localStorage;

const getId = (target, key, id = undefined) => {
  id = id || target.localStorageId || target.getAttribute('local-storage-id');
  if (!id) {
    return undefined;
  }
  if (typeof id === 'string') {
    return `${id}-${key}`;
  }
  if (typeof id === 'function') {
    // @ts-expect-error
    return `${id(target)}-${key}`;
  }
  throw new Error('idFn should only be string or function');
};

const getGetter = (
  target,
  local,
  key,
  initialGetter,
  // @ts-expect-error
  { id, typeParam = String, initializer } = {},
) =>
  function getter() {
    const _id = getId(target, key, id);
    let val;
    if (_id) {
      // @ts-expect-error
      val = defaultConverter.fromAttribute(
        globalLocalStorage.getItem(_id),
        typeParam,
      );
    } else {
      val = local;
    }
    if (val === undefined || val === null) {
      val = (initializer && initializer()) || initialGetter.call(target);
    }
    return val;
  };

// @ts-expect-error
const getSetter = (target, local, key, { id, typeParam = String } = {}) =>
  function setter(val) {
    const _id = getId(target, key, id);
    local = val;
    if (_id) {
      // @ts-expect-error
      val = defaultConverter.toAttribute(val, typeParam); // convert string to specified type
      globalLocalStorage.setItem(_id, val);
    }
    // @ts-expect-error
    this.requestUpdate && this.requestUpdate(); // for updating-element
  };

// todo auto compute id with parent path and id ?
/*
 * @param {(String | Function)} options.id:  string or fn for generating id. use instance's localStorageId+fieldName or disabled when all not set.
 *
 * performance tested: reading 1million/s, similar to object access. so it's ok to read directly from storage.
 * for big data might be problem when rerender very often, can try cache it.
 * */
// @ts-expect-error
export const localStorage = function ({ id, typeParam = String } = {}) {
  return function decorator(target, key, descriptor) {
    const { initializer } = descriptor;
    delete descriptor.initializer;
    delete descriptor.writable;
    let local; // fallback when not set local-storage-id

    descriptor.get = getGetter(target, local, key, descriptor.get, {
      id,
      typeParam,
      initializer,
    });

    descriptor.set = getSetter(target, local, key, { id, typeParam });

    return descriptor;
  };
};

export const localStorageMixin = function (
  clz,
  { targetProperty, id, typeParam = String },
) {
  return class extends clz {
    constructor() {
      super();
      let local; // fallback when local-storage-id is not set
      const [, propertyDescriptor] = getPropertyDescriptor(
        this,
        targetProperty,
      );
      delete propertyDescriptor.initializer;
      delete propertyDescriptor.writable;
      const getterFn = getGetter(
        this,
        local,
        targetProperty,
        propertyDescriptor.get,
        // @ts-expect-error
        {
          id,
          typeParam,
        },
      );
      const setterFn = getSetter(this, local, targetProperty, {
        id,
        typeParam,
      });
      Object.defineProperty(this, targetProperty, {
        get: getterFn,
        set: setterFn,
        configurable: true,
      });
    }
  };
};
