import { css, html } from 'lit';

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
const canvas = document.createElement('canvas');

function getTextWidth(text, font) {
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }
  return 1;
}

function getCssStyle(element, prop) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

export default function ColumnResizing(clz) {
  return class extends clz {
    // language=CSS
    static styles = css`
      .header .resize {
        overflow: visible;
      }

      .row:hover {
        --resizer-display: flex;
      }

      .header .resize .resizer {
        top: 0px;
        width: 10px;
        height: 100%;
        cursor: e-resize;
        position: absolute;
        z-index: 2;
        justify-content: center;
        display: var(--resizer-display, none);
        align-items: center;
        user-select: none;
        background: inherit; /* Hides any text behind this element */
      }

      .header .resize .resizer.left {
        left: -10px;
      }

      /* This allows column headers to be centered with text-align set in the css property of the column in layercfg */
      .resize .cell {
        width: 100% !important;
        display: block;
      }

      /* allows columns added to the table after a resizing to have more than 0 width */
      /* also allows for custom css to set a relative width for columns instead of a fixed width */
      .cell {
        width: 100%;
      }
    `;

    renderStarted = true;

    _columnSizeStyle: any = {};

    minimumWidth: number = 120;

    // the padding on cells in table rows, used when auto resizing to content width (double click)
    cellPadding: number = 20;

    get renderingColumns() {
      return super.renderingColumns?.map(c => {
        const col = { ...c };
        const style = this._columnSizeStyle[col.field];
        if (style) {
          Object.keys(style).forEach(key => {
            if (!col.css) col.css = '';
            // Try not to mangle custom css too much
            // This made more sense to me than overriding renderCell, you are free to disagree
            // This also stop you from having css conflicting with the custom css
            if (col.css.indexOf(`${key}:`) >= 0) {
              const regex = new RegExp(`${key}:[^;]+;?`, `i`);
              col.css = col.css.replace(regex, `${key}: ${style[key]};`);
            } else {
              col.css += `${key}: ${style[key]};`;
            }
          });
        }
        return col;
      });
    }

    findPreviousColumn(field) {
      let previousColumn = this.renderingColumns[0];
      this.renderingColumns.forEach((column, index) => {
        if (column.field === field && index > 0) {
          previousColumn = this.renderingColumns[index - 1];
        }
      });
      return previousColumn;
    }

    // Sort columns by current width
    // middle column after sorting becomes 100% standard (100% because then if someone hides all other columns the table will still be full)
    // apply width calculation to all columns based on width after resize

    // Used to calculate column width percentages later, call this before changing the width of a column
    setStandardWidth() {
      const allColumns: Array<HTMLElement> = Array.from(
        this.shadowRoot.querySelectorAll('.resize.cell'),
      );
      allColumns.sort((a, b) => a.offsetWidth - b.offsetWidth);
      this.standardWidth =
        allColumns[Math.trunc(allColumns.length / 2)].offsetWidth;
    }

    initResize(event, col) {
      this.removeListeners();
      this.previousColumn = this.findPreviousColumn(col.field);
      this.previousColumnElement = this.shadowRoot.getElementById(
        `resize-col-${this.previousColumn.field}`,
      );
      this.postColumn = col;
      this.postColumnElement = this.shadowRoot.getElementById(
        `resize-col-${col.field}`,
      );

      this.previousColumnElement.style.left = `${this.previousColumnElement.offsetLeft}px`;
      this.previousColumnWidth = this.previousColumnElement.offsetWidth;
      this.postColumnWidth = this.postColumnElement.offsetWidth;
      this.startMouseX = event.clientX;

      this.setStandardWidth();

      this.resizeEvent = e => this.Resize(e);
      this.stopResizeEvent = () => this.stopResize();
      window.addEventListener('mousemove', this.resizeEvent, false);
      window.addEventListener('mouseup', this.stopResizeEvent, false);
    }

    Resize(e) {
      const newPrevColumnWidth =
        this.previousColumnWidth - (this.startMouseX - e.clientX);
      const newPostColumnWidth =
        this.postColumnWidth - (e.clientX - this.startMouseX);
      if (
        newPrevColumnWidth < this.minimumWidth ||
        newPostColumnWidth < this.minimumWidth
      ) {
        return;
      }
      this.previousColumnElement.style.width = `${newPrevColumnWidth}px`;
      this.postColumnElement.style.width = `${newPostColumnWidth}px`;
    }

    removeListeners() {
      window.removeEventListener('mousemove', this.resizeEvent, false);
      window.removeEventListener('mouseup', this.stopResizeEvent, false);
    }

    // This requires you set the width of the column before calling it
    resizeColumns() {
      const allColumns: Array<HTMLElement> = Array.from(
        this.shadowRoot.querySelectorAll('.resize.cell'),
      );
      allColumns.forEach(column => {
        const field = column.getAttribute('field');
        if (field) {
          this._columnSizeStyle[field] = {
            width: `${(column.offsetWidth / this.standardWidth) * 100}%`,
          };
        }
      });
    }

    stopResize() {
      this.previousColumnElement.style.left = `unset`;
      this.removeListeners();
      this.resizeColumns();

      this.requestUpdate();
    }

    // This can't work for cells with custom content (not field value text)
    fitContent(field) {
      const existingCell = this.shadowRoot.querySelector(
        `.scroller .cell.col-${field}`,
      );
      const font = getCanvasFont(existingCell);

      let maxWidth = 0;

      this.data.forEach(item => {
        if (Object.prototype.hasOwnProperty.call(item, field)) {
          const width = getTextWidth(item[field], font) || 1;
          if (width > maxWidth) {
            maxWidth = width;
          }
        }
      });
      maxWidth = Math.ceil(maxWidth);

      if (maxWidth < 0) {
        return;
      }

      if (maxWidth < this.minimumWidth) {
        maxWidth = this.minimumWidth;
      }

      this.setStandardWidth();
      const headerElement = this.shadowRoot.getElementById(
        `resize-col-${field}`,
      );
      headerElement.style.width = `${maxWidth + this.cellPadding + 1}px`;
      this.resizeColumns();

      this.requestUpdate();
    }

    // I'm putting the resizer in the following column and aligning it to the left because I can't know which column is the last
    // The last column needs to not have a resizer on the right.
    // This way I can remove it from the first one and the last appears not to have one.
    _renderHeaderCell(col) {
      if (col.field === '__options') {
        this.renderStarted = true;
        return super._renderHeaderCell(col);
      }
      const resizer = this.renderStarted
        ? ''
        : html`<div
            class="resizer left"
            @mousedown=${e => {
              this.initResize(e, col);
            }}
            @dblclick=${() =>
              this.fitContent(this.findPreviousColumn(col.field).field)}
          >
            ║
          </div>`;
      const fisrt = this.renderStarted ? 'firstResizable' : ``;
      this.renderStarted = false;
      return html`
        <div
          id="${`resize-col-${col.field}`}"
          field="${col.field}"
          class="resize cell ${col?.format?.type ||
          ''} col-${col.field} ${fisrt}"
          style="${col.labelCss || col.css || ''} ${col.width
            ? `width:${col.width}px;`
            : ''}"
        >
          ${super._renderHeaderCell(col)} ${resizer}
        </div>
      `;
    }
  };
}
