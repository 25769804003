export default function Mix(...clzs) {
  // TODO temp, try to do same as properties.
  return clzs.reduce((c1, c2) => {
    let c3;
    if (Array.isArray(c2)) {
      c3 = c2[0](c1, c2[1]);
    } else {
      c3 = c2(c1);
    }
    c3.styles = [].concat(c1.styles || []).concat(c3.styles || []);
    return c3;
  });
}
