/* eslint-disable func-names */
import { html } from 'lit';
import aspect from '../common/aspect';

const _loaderMixin = {
  __$loaderCount: 0,

  get loading() {
    return this.__$loaderCount > 0;
  },

  promiseLoader(p) {
    this.__$loaderCount += 1;
    // @ts-expect-error
    this.requestUpdate();
    return p.finally(() => {
      this.__$loaderCount -= 1;
      // @ts-expect-error
      this.requestUpdate();
    });
  },

  // default loader
  _renderLoader() {
    if (this.loading) {
      return html`
        <style>
          .spiner-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 99;
          }
          paper-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        </style>
        <div class="spiner-wrapper"><paper-spinner active></paper-spinner></div>
      `;
    }
    return '';
  },
};

const loaderTargetFn = function (prototype) {
  Object.assign(prototype, _loaderMixin);
  aspect(
    prototype,
    'render',
    origin =>
      function () {
        // @ts-expect-error
        return html`${this._renderLoader()}${origin.apply(this)}`;
      },
  );
};

export const loaderFn = function (target) {
  loaderTargetFn(target.prototype);
};

export const loader = function () {
  return function decorator(target) {
    loaderFn(target);
  };
};

export const loaderMixin = function (clz) {
  return class extends clz {
    constructor() {
      super();
      loaderTargetFn(this);
    }
  };
};
