/* eslint-disable wc/no-self-class */
import { css } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';
import KiIcon from './ki-icon';

@customElement('ki-icon-btn')
export default class KiIconBtn extends KiIcon {
  // language=CSS
  static styles = [
    ...KiIcon.styles,
    css`
      :host {
        display: block;
        color: gray;
        fill: gray;
        background: white;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 40px;
        cursor: pointer;
        position: relative;
      }
      :host(:hover) ki-icon::part(icon) {
        fill: #4a4a49;
      }

      :host([active]) {
        color: white;
        fill: white;
        background: var(--theme-color-secondary, #0056a0);
      }

      :host(.ripple) {
        background-position: center;
        transition: background 0.8s;
      }
      :host(.ripple:hover) {
        background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%)
          center/15000%;
      }
      :host(.ripple:active) {
        background-color: var(--theme-color-secondary, #0056a0);
        background-size: 100%;
        transition: background 0s;
      }

      :host(.disabled) {
        pointer-events: none;
        opacity: 0.5;
      }

      svg {
        max-width: 5em;
      }
    `,
  ];

  @property({ type: Boolean, reflect: true })
  active = false;

  @property({ type: String })
  tooltip: string = '';

  @property({ type: Boolean })
  disabled: boolean = false;

  selected: boolean = false;

  @property({ type: Boolean })
  toggle: boolean = false;

  clickHandler;

  connectedCallback() {
    // @ts-expect-error
    if (super.connectedCallback) super.connectedCallback();
    this.clickHandler = () => {
      if (this.toggle) this.active = !this.active;
    };
    this.addEventListener('click', this.clickHandler);
    if (this.selected) this.classList.add('selected');
  }

  disconnectedCallback() {
    // @ts-expect-error
    if (super.disconnectedCallback) super.disconnectedCallback();
    this.removeEventListener('click', this.clickHandler);
  }
}
