import { Loader } from './Loader';
// deprecated
/*
 *
 * provide loader counting functionality
 *
 * class A extend  LoaderMixin(parentClass){
 *
 *   someFn(){
 *     ...
 *     promiseLoader(new Promise())
 *     ...
 *
 *     console.log(this.loading); //show loading status
 *   }
 *
 * }
 *
 * */
export default function LoaderMixin(clz) {
  return class extends clz {
    _loader = new Loader();

    loaderUpdate() {
      this.requestUpdate();
    }

    constructor() {
      super();
      this._loader.addEventListener('loaderCountChanged', () =>
        this.loaderUpdate(),
      );
    }

    get loading() {
      return this._loader.loading;
    }

    promiseLoader(p) {
      return this._loader.promiseLoader(p);
    }

    // default loader
    _renderLoader() {
      return this._loader._renderLoader();
    }
  };
}
