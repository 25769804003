import { last, union } from 'lodash-es';

export class PopupUtil {
  public static fileMatchesRange(tshref, ranges, alarms): boolean {
    const whitelist = union(
      ranges.map(r => r.data),
      alarms?.file,
    );
    const filename = last(tshref.split('/'));
    return whitelist.includes(filename);
  }
}
