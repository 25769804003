const maxIterations = 150;

export default function getPropertyDescriptor(object, propertyName) {
  let iteration = 0;
  let proto = Object.getPrototypeOf(object);
  let targetDescriptor;
  do {
    targetDescriptor = Object.getOwnPropertyDescriptor(proto, propertyName);
    iteration += 1;
    if (targetDescriptor === undefined) {
      proto = Object.getPrototypeOf(proto);
    }
  } while (
    proto !== undefined &&
    proto !== null &&
    targetDescriptor === undefined &&
    iteration <= maxIterations
  );

  if (iteration === maxIterations) {
    console.warn(`Max iterations reached.`);
  }

  if (targetDescriptor === undefined) {
    console.error(`couldn't find property "${propertyName}".`);
  }

  return [proto, targetDescriptor];
}
