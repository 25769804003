/* eslint-disable func-names */

export const getQueryObject = (target, selector, { all = false } = {}) => {
  if (all) {
    return target.renderRoot.querySelectorAll(selector);
  }
  return target.renderRoot.querySelector(selector);
};

export const query = function (selector, { all = false } = {}) {
  return (target, _key, descriptor) => {
    delete descriptor.writable;
    delete descriptor.initializer;
    descriptor.get = getQueryObject(target, selector, { all });
    return descriptor;
  };
};
