// TODO move to base
export default function f(claz) {
  const anonymity = class extends claz {
    static __$propertyDefaultValues = {};

    static getPropertyDescriptor(name, key, _options) {
      if (Object.hasOwnProperty.call(_options, 'initial')) {
        this.__$propertyDefaultValues[key] = _options.initial;
      }

      if (Object.hasOwnProperty.call(_options, 'default')) {
        this.__$propertyDefaultValues[key] = () => _options.default;
      }

      return super.getPropertyDescriptor(name, key, _options);
    }

    constructor() {
      super();
      Object.entries(anonymity.__$propertyDefaultValues).forEach(
        ([k, initial]) => {
          // @ts-expect-error
          this[k] = initial();
        },
      );
    }
  };

  return anonymity;
}
