/* eslint-disable class-methods-use-this */
import '../ki-icon/ki-icon';
import { html, css } from 'lit';
import first from 'lodash-es/first';
import get from 'lodash-es/get';

export default function SortAble(clz) {
  return class extends clz {
    // language=CSS
    static styles = css`
      .sortable {
        cursor: pointer;
      }

      .header-content .icons,
      .header-content .sort-index {
        display: none;
      }

      .header-content .sort-index {
        font-size: 8px;
        line-height: 8px;
        transform: translateY(-10px);
        color: var(--theme-color, #0056a0);
      }

      .header-content.sortable .icons {
        padding-left: 10px;
        display: inline-block;
      }

      .header-content.sortable .icons,
      .header-content.sortable .sort-index {
        display: inline-block;
      }

      .header-content.sortable ki-icon {
        display: block;
        color: lightgray;
      }

      .header-content.sortable ki-icon {
        visibility: hidden;
      }

      .header-content.sortable:hover ki-icon {
        visibility: visible;
      }

      .header-content.sortable ki-icon[icon~='ki-sort-up'],
      .header-content.sortable ki-icon[icon~='ki-sort-down'] {
        color: var(--theme-color, #0056a0);
        visibility: visible;
      }
    `;

    static get properties() {
      return {
        sort: {
          type: Array,
        },
        multisort: {
          type: Boolean,
        },
        // Transform Ä,Ö,Ü to A,O,U to be conform with DIN5007
        normalizeSpecialCharacters: {
          type: Boolean,
        },
      };
    }

    constructor() {
      super();
      this.sort = [];
      this.normalizeSpecialCharacters = false;
    }

    render() {
      this._sortData();
      return super.render();
    }

    _renderHeaderCell(col) {
      // put text in td
      let changeOrder;
      let removeOrder;
      let sortIndex = '';
      let sort;
      let sortIcon = 'ki-sort-v';
      if (col.sortable) {
        sort = this.sort.find(_sort => _sort.field === col.field);
        if (sort) {
          sortIndex = this.multisort ? this.sort.indexOf(sort) + 1 : '';
          sortIcon = sort.ascending ? 'ki-sort-up' : 'ki-sort-down';
          changeOrder = () => {
            sort.ascending = !sort.ascending;
            // move to first
            this.sort = [sort, ...this.sort.filter(a => a !== sort)];
          };
          removeOrder = () => {
            this.sort = [...this.sort.filter(a => a !== sort)];
          };
        } else {
          changeOrder = () => {
            this.sort = [
              {
                field: col.field,
                sortBy: col.sortBy,
                ascending: false,
                format: col.format,
                useDIN5007: col.useDIN5007,
              },
              ...this.sort,
            ];
          };
        }
      }

      return col.renderHeaderCell
        ? col.renderHeaderCell()
        : // The ignore is important because prettier inserts whitespace which the table renders
          // prettier-ignore
          html`
            <div
              style="${col.labelCss || col.css || ''}"
              title="${col.title || col.label}"
              class="cell ${col?.format?.type ||
              ''} col-${col.field} ${col.sortable ? 'sortable' : ''}"
            ><div
                @dblclick="${this.multisort && removeOrder}"
                @click="${changeOrder}"
                class="header-content ${col.sortable ? 'sortable' : ''}"
              ><span class="col-label">${col.label}</span>
                <span class="icons">
                  <ki-icon icon="ki ${sortIcon}"></ki-icon>
                </span>
                <div class="sort-index">${sortIndex}</div>
              </div>
            </div>
          `;
    }

    _sortData() {
      // Check if we should apply DIN5007 / defined in layercfg -> col
      if (first(this.sort)?.useDIN5007) this.normalizeSpecialCharacters = true;

      if (this.sort.length > 0) {
        if (!this.multisort) {
          this.sort = this.sort.slice(0, 1);
        }

        this.data.sort((a, b) => {
          const sort = [...this.sort];
          let result = 0;
          do {
            const _sort = sort.shift();
            const compareProperty = _sort.sortBy || _sort.field;
            let aValue = get(a, compareProperty);
            let bValue = get(b, compareProperty);
            if (typeof aValue === 'string') {
              aValue = aValue.toLowerCase();
              if (this.normalizeSpecialCharacters)
                aValue = this._normalizeSpecChars(aValue);
            }
            if (typeof bValue === 'string') {
              bValue = bValue.toLowerCase();
              if (this.normalizeSpecialCharacters)
                bValue = this._normalizeSpecChars(bValue);
            }
            const format =
              typeof _sort.format === 'string'
                ? _sort.format
                : _sort.format?.type;
            if (['textnumber', 'number', 'wiskinumber'].indexOf(format) >= 0) {
              const parsedA =
                typeof aValue === 'string'
                  ? parseFloat(aValue.replace(',', ''))
                  : aValue;
              const parsedB =
                typeof bValue === 'string'
                  ? parseFloat(bValue.replace(',', ''))
                  : bValue;
              if (Number.isNaN(parsedA) && Number.isNaN(parsedB)) {
                aValue = 0;
                bValue = 0;
              } else if (Number.isNaN(parsedA)) {
                bValue = parsedB;
                aValue = bValue - 1;
              } else if (Number.isNaN(parsedB)) {
                aValue = parsedA;
                bValue = aValue - 1;
              } else {
                aValue = parsedA;
                bValue = parsedB;
              }
            }
            if (aValue === bValue) {
              result = 0;
            } else {
              result = (aValue > bValue ? 1 : -1) * (_sort.ascending ? 1 : -1);
            }
          } while (result === 0 && sort.length > 0);
          return result;
        });
      }
    }

    // Enforce DIN5007 sorting order
    _normalizeSpecChars(str: string): string {
      let _s: string = str;
      _s = _s.replace('ä', 'a');
      _s = _s.replace('ö', 'o');
      _s = _s.replace('ü', 'u');
      return _s;
    }
  };
}
