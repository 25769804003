let config: any = {};
let routes: Array<any> = [];

let initialized = false;

const _assertInitialized = () => {
  if (!initialized) {
    throw new Error("Config hasn't been loaded yet!");
  }
};

const getRoutesFromConfig = (configRoutes, importActions) =>
  configRoutes.map(route => {
    const newRoute = { ...route };

    const actions = importActions.filter(
      act => act.component === route.component,
    );

    if (actions.length > 0) {
      // should be only one
      newRoute.action = actions[0].importAction;
    }

    if (route.children && route.children.length > 0) {
      newRoute.children = getRoutesFromConfig(route.children, importActions);
    }

    return newRoute;
  });

export const initConfig = async (configPath, importActions) => {
  const resp = await fetch(configPath);
  config = await resp.json();
  routes = getRoutesFromConfig(config.route, importActions);

  initialized = true;
  return config;
};

export const getConfig = () => config;

export const getRoutes = () => {
  _assertInitialized();
  return routes;
};

export const getNavItemLabel = path => {
  _assertInitialized();
  let ret = '';
  config.navList.forEach(navItem => {
    if (navItem.link === path) {
      ret = navItem.breadcrumb ?? navItem.label;
    }
    if (navItem.items) {
      navItem.items.forEach(subNavItem => {
        if (subNavItem.link === path) {
          ret = subNavItem.breadcrumb ?? subNavItem.label;
        }
      });
    }
  });

  return ret;
};

export const getNavItems = () => {
  _assertInitialized();
  return config.navList;
};

export const getApiPath = () => {
  _assertInitialized();
  return config.apiPath;
};

export const getLeftLogoLink = () => {
  _assertInitialized();
  return config.leftLogoLink;
};

export const getRightLogoLink = () => {
  _assertInitialized();
  return config.rightLogoLink;
};

export const getLogoOptions = () => {
  _assertInitialized();
  return config.logo;
};

export const getCsvDelimiter = () => {
  _assertInitialized();
  return config.csvDelimiter ?? ';';
};
