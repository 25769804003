import { css, html, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';
import { endsWith } from 'lodash-es/string';

/*
 * position related to it's parent with (position: relative;)
 *
 * */
@customElement('ki-popup')
export default class KiPopup extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      display: block;
      position: fixed;
      outline: none;
      z-index: 999;
      cursor: default;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      background: white;

      transition: visibility linear 0s 0s, all ease-in-out 0.6s 0.1s;

      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }

    :host(.visible) {
      visibility: visible;
      pointer-events: visible;
      opacity: 1;
    }
    #focus-node:focus {
      outline: none;
    }
  `;

  @property({ type: Boolean, reflect: true })
  visible: boolean = false;

  @property({ type: String })
  for: string | undefined;

  @property({ type: String })
  top: string | undefined;

  @property({ type: String })
  left: string | undefined;

  @property({ type: String })
  right: string | undefined;

  @property({ type: String })
  bottom: string | undefined;

  @property({ type: String })
  sizeref: string | undefined;

  @property({ type: Boolean })
  autofit: boolean = false;

  // if for is not set , use parent node
  get target() {
    const { parentNode } = this;
    const ownerRoot = this.getRootNode();
    let target;
    if (this.for) {
      // @ts-expect-error
      target = ownerRoot.querySelector(`#${this.for}`);
    } else {
      // use host if parent is root of shadow dom
      target =
        parentNode?.nodeType === Node.DOCUMENT_FRAGMENT_NODE
          ? // @ts-expect-error
            ownerRoot.host
          : parentNode;
    }
    return target;
  }

  render() {
    // @ts-expect-error
    this.renderRoot.host.classList.toggle('visible', this.visible);
    // language=html
    return html`
      <div
        id="focus-node"
        part="focus-node"
        tabindex="-1"
        @mouseup="${e => {
          e.stopPropagation();
        }}"
      >
        <slot></slot>
      </div>
    `;
  }

  firstUpdated() {
    this._addEventListener();
  }

  updated() {
    if (this.visible) {
      this.updatePosition();
      // this.updateComplete.then(() => {
      //     const focusNode = this.renderRoot.getElementById('focus-node');
      //     focusNode.focus();
      // });
      // console.log(focusNode); // TODO dom node change make the focus not working.
      // setTimeout(() => focusNode.focus(), 500); // workaround: focus not working in runtime
    }
  }

  // closeAfterMilliSeconds: auto close popup after N milliseconds
  show({ closeAfterMilliSeconds }) {
    if (closeAfterMilliSeconds) {
      setTimeout(() => this.hide(), closeAfterMilliSeconds);
    }
    this.visible = true;
  }

  hide() {
    this.visible = false;
    this.dispatchEvent(new CustomEvent('popup-hiding', {}));
  }

  updatePosition() {
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
    // need set display not 'none'
    const { target } = this;
    const parentRect = {
      left: 0,
      top: 0,
    };
    const targetRect = target.getBoundingClientRect();

    const popupLeft =
      this.left && this._calculate(parentRect, targetRect, 'left');
    const popupTop = this.top && this._calculate(parentRect, targetRect, 'top');
    const popupRight =
      this.right && this._calculate(parentRect, targetRect, 'right');
    const popupBottom =
      this.bottom && this._calculate(parentRect, targetRect, 'bottom');

    this.style.left = popupLeft && `${popupLeft}px`;
    this.style.top = popupTop && `${popupTop}px`;

    this.style.right = popupRight && `${popupRight}px`;
    this.style.bottom = popupBottom && `${popupBottom}px`;
  }

  _calculate(parentRect, targetRect, position) {
    // position: left, right, top, bottom
    const positionVal = this[position]; // positionVal: 0, 1px, 22px /100%
    const isPercentage = endsWith(positionVal, '%');

    let targetPosition = targetRect[position] - parentRect[position];
    if (position === 'bottom' || position === 'right') {
      targetPosition *= -1;
    }

    let positionNum;
    if (isPercentage) {
      positionNum = Number(positionVal.slice(0, positionVal.length - 1));
    } else {
      positionNum = endsWith(positionVal, 'px')
        ? Number(positionVal.slice(0, positionVal.length - 2))
        : Number(positionVal);
    }
    const sizeNode =
      this.sizeref === 'popup' ? this.getBoundingClientRect() : targetRect;
    let popup;
    if (isPercentage) {
      popup =
        targetPosition +
        ((position === 'top' || position === 'bottom'
          ? sizeNode.height
          : sizeNode.width) *
          positionNum) /
          100;
    } else {
      popup = targetPosition + positionNum;
    }

    return popup;
  }

  _addEventListener() {
    // click: this.events = {
    //     visible: 'mousedown',
    //     invisible: 'mouseup'
    // }

    // todo hover: this.events = {
    //     visible: 'mouseover',
    //     invisible: 'mouseout'
    // }

    window.addEventListener('resize', () => {
      this.requestUpdate();
    });

    window.addEventListener('mouseup', () => {
      this._clickOutsideHanlder();
    });
  }

  _clickOutsideHanlder() {
    this.visible && this.hide();
  }
}
