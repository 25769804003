/* eslint-disable max-classes-per-file */
import '../ki-icon/ki-icon';
import { html, css } from 'lit';
import '../ki-popup/ki-popup';
import '../ki-list/ki-list';
import '../ki-checkbox/ki-checkbox';
/* eslint-disable-next-line import/extensions */
import { repeat } from 'lit/directives/repeat.js';
import debounce from 'lodash-es/debounce';

/*
 * col:{
 *   hideAble: Boolean,
 *   hide: Boolean
 * }
 *
 *
 * */
export default function ColumnHider(clz) {
  return class extends clz {
    // language=CSS
    static styles = css`
      .header-cell-hider {
        width: 50px;
        text-align: center;
        cursor: pointer;
        color: gray;
      }
      .header-cell-hider:hover {
        color: #4a4a49;
      }

      .hider.active {
        padding: 2px;
        background-color: var(--theme-color, #0056a0);
        color: white;
        fill: white;
        border-radius: 15px;
      }

      ki-popup {
        right: 10px;
        text-align: left;
        bottom: 0;
        /*temp solution*/
      }

      .header-cell-hider ki-popup {
        padding: 0;
        overflow: clip;
        display: flex;
      }
      .header-cell-hider ki-popup::part(focus-node) {
        display: flex;
        flex: 1;
      }

      ki-list {
        flex: 1;
        overflow: auto;
        min-width: 200px;
        /* adjusting for scroll bar */
        padding-right: 15px;
      }
      ki-list-item {
        padding: 5px;
        display: flex;
        align-items: center;
      }
      ki-checkbox {
        padding: 10px;
      }
    `;

    constructor() {
      super();
      this.__hiderOptionVisible = false;
      this._hiddenColumns = null;
    }

    static get properties() {
      return {
        hiddenColumns: { type: Array },
        __hiderOptionVisible: {
          type: Boolean,
        },
      };
    }

    get hiddenColumns() {
      return (
        this._hiddenColumns ||
        this.columns
          ?.filter(item => item.hideAble && item.hide)
          .map(i => i.field) ||
        []
      );
    }

    // TODO: More generic solution? e.g. set target element from outside
    _togglePopupColumnHider() {
      this.dispatchEvent(
        new CustomEvent('toggledPopupColumnHider', {
          detail: {
            visible: this.__hiderOptionVisible,
          },
          bubbles: true,
          composed: true,
        }),
      );
    }

    // Callum Ramage:
    // This avoids a race condition between the ki-popup hiding from an outside click and the header icon being clicked.
    // Both trigger at the same time with the ki-popup triggering first.
    setHiderVisible = debounce(visible => {
      this.__hiderOptionVisible = visible;
    }, 100);

    get renderingColumns() {
      if (super.renderingColumns.filter(item => item.hideAble).length > 0) {
        return super.renderingColumns
          .filter(col => this.hiddenColumns.indexOf(col.field) < 0)
          .concat([
            {
              field: '__options',
              renderHeaderCell: () => {
                const togglePopup = () => {
                  this.setHiderVisible(!this.__hiderOptionVisible);
                  this._togglePopupColumnHider();
                };

                return html`
                  <div class="cell header-cell-hider">
                    <div class="header-content" @click="${togglePopup}">
                      <ki-icon
                        icon="ki ki-ellipsis-h"
                        class="hider ${this.__hiderOptionVisible
                          ? 'active'
                          : ''}"
                        id="hider"
                      ></ki-icon>
                    </div>
                    <ki-popup
                      for="hider"
                      right="0"
                      top="100%"
                      .visible="${this.__hiderOptionVisible}"
                      @popup-hiding=${() => {
                        this.setHiderVisible(false);
                        this._togglePopupColumnHider();
                      }}
                    >
                      ${this.__renderHiderColumnOptions()}
                    </ki-popup>
                  </div>
                `;
              },
            },
          ]);
      }
      return super.renderingColumns;
    }

    __renderHiderColumnOption(item) {
      return html`
        <ki-list-item>
          <ki-checkbox
            class="hiderBox"
            .name="${item.field}"
            .checked="${this.hiddenColumns.indexOf(item.field) < 0}"
            @change="${this._toggleHiderOption}"
          ></ki-checkbox>
          <span class="label">${item.title || item.label}</span>
        </ki-list-item>
      `;
    }

    __renderHiderColumnOptions() {
      const options = this.columns.filter(item => item.hideAble);
      const keyFn = op => op.field;
      return html`
        <ki-list
          >${repeat(
            options,
            keyFn,
            this.__renderHiderColumnOption.bind(this),
          )}</ki-list
        >
      `;
    }

    get __hiderOptionCheckBox() {
      return this.renderRoot.querySelectorAll('ki-checkbox.hiderBox');
    }

    _toggleHiderOption() {
      const list: Array<any> = [];
      this.__hiderOptionCheckBox.forEach(c => {
        c.checked || list.push(c.name);
      });
      this._hiddenColumns = list;
      this.requestUpdate();
    }
  };
}
