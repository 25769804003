// extension for ki-table
import { html, css } from 'lit';
/* eslint-disable-next-line import/extensions */
import { repeat } from 'lit/directives/repeat.js';
import { getQueryObject } from '../../decorators/query';
import '../ki-checkbox/ki-checkbox';

export default function Selection(clz) {
  // TODO multi / single ?

  return class extends clz {
    static styles = css`
      .body .row.selected,
      .body .row.selected:hover {
        background: var(--theme-color, #0056a0);
        color: var(--theme-text-color, white);
      }
      .selection-col {
        font-size: 22px;
        vertical-align: middle;
        width: 40px;
      }
      .header .row {
        line-height: 25px;
      }
    `;

    static get properties() {
      return {
        multiselection: {
          type: Boolean,
        },
        selection: {
          type: Array,
        },
      };
    }

    set selection(val) {
      this.__selectionSet = new Set(val);
      this.requestUpdate();
    }

    constructor() {
      super();
      this.selectionActive = true;
      this.__selectionSet = new Set();
    }

    get __selectAllCheckbox() {
      return getQueryObject(this, '#select-all-checkbox');
    }

    _isSelected(row) {
      return this.__selectionSet.has(row[this.idproperty]);
    }

    _addSelection(row) {
      this.__selectionSet.add(row[this.idproperty]);
      this.requestUpdate();
      this.__fireSelection();
    }

    _toggleSelection(row) {
      if (this.__selectionSet.has(row[this.idproperty])) {
        this.__selectionSet.delete(row[this.idproperty]);
      } else {
        if (!this.multiselection) {
          this.__selectionSet.clear();
        }
        this.__selectionSet.add(row[this.idproperty]);
      }
      this.requestUpdate();
      this.__fireSelection();
    }

    _clearSelection() {
      this.__selectionSet.clear();
      this.requestUpdate();
      this.__fireSelection();
    }

    __fireSelection() {
      this.dispatchEvent(
        new CustomEvent('selection', {
          bubbles: true,
          composed: true,
          detail: { selection: Array.from(this.__selectionSet.values()) },
        }),
      );
    }

    _selectAll() {
      const flag = this.__selectAllCheckbox.checked;
      if (flag) {
        this.selection = this.data.map(d => d[this.idproperty]);
      } else {
        this.selection = [];
      }
      this.__fireSelection();
    }

    // TODO use get renderingColumns;

    _renderHeader() {
      if (!this.selectionActive) return super._renderHeader();

      return html`
        <div class="header">
          <div class="row">
            <div class="cell selection-col">
              <div class="header-content">
                <ki-checkbox
                  id="select-all-checkbox"
                  .checked="${this.__selectionSet.size === this.data.length}"
                  .indeterminate="${this.__selectionSet.size <
                    this.data.length && this.__selectionSet.size > 0}"
                  @change="${() => {
                    this._selectAll();
                  }}"
                ></ki-checkbox>
              </div>
            </div>
            ${repeat(
              this.renderingColumns,
              (col: { field: any }) => col.field,
              col => this._renderHeaderCell(col),
            )}
          </div>
        </div>
      `;
    }

    renderRow(item) {
      if (!this.selectionActive) return super.renderRow(item);
      // TODO consider
      // language=html
      return html`
        <div
          class="row"
          @mouseenter="${e => this.mouseenter(e, item)}"
          @mouseleave="${e => this.mouseleave(e, item)}"
          @click="${() => {
            this._onRowClick(item);
          }}"
        >
          <div class="cell selection-col" @click="${e => e.stopPropagation()}">
            <ki-checkbox
              title="demander le téléchargement de données"
              .checked="${this._isSelected(item)}"
              @change="${() => {
                this._toggleSelection(item);
              }}"
            ></ki-checkbox>
          </div>
          ${this.renderingColumns.map(col => this._renderCell(col, item))}
        </div>
      `;
    }
  };
}
