import get from 'lodash-es/get';

const escapeCsv = (delimiter, text) => {
  if (text) {
    return `${text}`.includes(delimiter) ? `"${text}"` : text;
  }
  return '';
};

export default function Export(clz) {
  return class extends clz {
    toCSV(delimiter = ';', all = true, exportcolumns) {
      let result = '#';
      let columns = all ? this.columns : this.renderingColumns;

      if (exportcolumns) {
        columns = columns.filter(col => exportcolumns.includes(col.field));
      }

      columns.forEach((col, i) => {
        result += `${i === 0 ? '' : delimiter}${escapeCsv(
          delimiter,
          col.label,
        )}`;
      });
      result += '\r\n';
      this.data.forEach(item => {
        columns.forEach((col, i) => {
          result += i === 0 ? '' : delimiter;
          let displayValue = '';
          if (col.renderCell) {
            const renderedCell = col.renderCell(item);
            const index = col.renderCellIndex ?? 0;
            if (
              renderedCell.values &&
              (renderedCell.values.length ?? 0) > index
            ) {
              // eslint-disable-next-line prefer-destructuring
              displayValue = renderedCell.values[index];
            } else {
              displayValue = renderedCell;
            }
          } else if (col.field === '__tag') {
            displayValue = get(item, '__tag_label');
          } else {
            displayValue = get(item, col.field);
          }
          result += escapeCsv(delimiter, displayValue);
        });
        result += '\r\n';
      });
      return result;
    }
  };
}
