/* eslint-disable func-names */
import getPropertyDescriptor from './helper/getPropertyDescriptor';
import {
  setSearchParam,
  getSearchParam,
  getAllSearchParams,
} from '../components/ki-app/routing/ki-router';

const getGetter = (param, type, defaultValue) =>
  function getter() {
    const searchParamValue = getSearchParam(param);
    if (
      (defaultValue && searchParamValue === undefined) ||
      searchParamValue === null
    ) {
      return defaultValue;
    }
    if (type === 'array') {
      return getAllSearchParams(param);
    }
    return searchParamValue;
  };

const getSetter = (target, param, oldSetter) =>
  function setter(val) {
    setSearchParam(param, val);
    if (oldSetter) {
      oldSetter.call(target, val);
    }
  };

// @ts-expect-error
export const queryParam = function (param, { type, defaultValue } = {}) {
  return function decorator(target, _key, descriptor) {
    delete descriptor.initializer;
    delete descriptor.writable;
    descriptor.set = getSetter(target, param, descriptor.set);
    descriptor.get = getGetter(param, type, defaultValue);
    return descriptor;
  };
};

export const queryParamMixin = function (
  clz,
  { targetProperty, selector, type = String, defaultValue },
) {
  return class extends clz {
    constructor() {
      super();
      const [, propertyDescriptor] = getPropertyDescriptor(
        this,
        targetProperty,
      );
      delete propertyDescriptor.initializer;
      delete propertyDescriptor.writable;

      const setterFn = getSetter(this, selector, propertyDescriptor.set);
      const getterFn = getGetter(selector, type, defaultValue);

      Object.defineProperty(this, targetProperty, {
        get: getterFn,
        set: setterFn,
        configurable: true,
      });
    }
  };
};
