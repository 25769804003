import { css, html, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';
import SearchSytaxQueryParser from '../../../tools/SearchSytaxQueryParser_ES6';
import '../ki-popup/ki-popup';

@customElement('ki-search-query-action')
export default class KiSearchQueryAction extends LitElement {
  // language=CSS
  static styles = css`
    .hide {
      display: none !important;
    }

    ki-icon {
      cursor: pointer;
    }

    ki-icon.ok {
      fill: #00c300;
    }

    ki-icon.ok:hover {
      fill: green;
    }

    ki-icon.error {
      fill: red;
    }

    ki-icon.error:hover {
      fill: #cf0000;
    }
  `;

  @property({ type: String })
  content: string | undefined;

  @property({ type: Array })
  fieldList: Array<any> = [];

  @property({ type: String })
  error: string = '';

  @property({ type: String })
  suggestion: any;

  @property({ type: Object })
  inputElement: any;

  @property({ type: Object })
  query: any;

  render() {
    let mode = '';
    if (!this.content) {
      mode = 'info';
    } else if (this.error === '') {
      mode = 'ok';
    } else {
      mode = 'error';
    }
    // language=html
    return html`
      <div id="marker">
        <ki-icon
          class="${mode === 'ok' ? mode : 'hide'}"
          icon="ki ki-check"
        ></ki-icon>
        <ki-icon
          class="${mode === 'error' ? mode : 'hide'}"
          icon="ki ki-info-circle"
        ></ki-icon>
      </div>
      <ki-popup id="msg-popup" for="marker" ?visible=${true}
        >${this.error}</ki-popup
      >
    `;
  }

  updated(changedProperties) {
    if (changedProperties.has('content')) {
      this._checkContent();
    }
  }

  _checkContent() {
    this.error = '';
    try {
      if (this.content) {
        this.query = SearchSytaxQueryParser.parse(this.content, {
          fields: this.fieldList,
        });
        this.dispatchEvent(
          new CustomEvent('query', {
            detail: {
              query: this.query,
            },
          }),
        );
      }
      this.dispatchEvent(
        new CustomEvent('suggestion', {
          detail: {
            suggestion: null,
          },
        }),
      );
    } catch (e: any) {
      this.error = e.message;
      this.suggestion = this.getSuggestions(e);
      this.dispatchEvent(
        new CustomEvent('suggestion', {
          detail: {
            suggestion: {
              options: this.suggestion,
              location: e.location,
            },
          },
        }),
      );
    }
  }

  getSuggestions(event) {
    let data: Array<any> = [];
    event.expected.forEach(item => {
      if (item.description === 'field name') {
        data = data.concat(this.fieldList);
      }
      if (item.type === 'literal' && item.text) {
        if (['('].indexOf(item.text) < 0) {
          data.indexOf(item.text) < 0 && data.push(item.text);
        }
      }
    });
    data = [...new Set(data)];
    return data;
  }
}
