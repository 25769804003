import en from './en/common.json';
import fr from './fr/common.json';
import de from './de/common.json';

export default {
  en: {
    common: en,
  },
  fr: {
    common: fr,
  },
  de: {
    common: de,
  },
};
