import { css, html, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';
import '../ki-popup/ki-popup';

// TODO collapse, popup with change log.
@customElement('ki-wcp-version-tag')
export default class KiWcpVersionTag extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      display: block;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      position: absolute;
    }

    .version {
      display: block;
      padding: 10px 20px;
      cursor: pointer;
      color: var(--theme-color);
    }
  `;

  @property({ type: Object })
  pkg: any = {};

  @property({ type: Boolean })
  showPopup: boolean = false;

  _packageJsonPath = './package.json';

  @property({ type: String })
  get packageJsonPath(): string {
    return this._packageJsonPath;
  }

  set packageJsonPath(val: string) {
    this._packageJsonPath = val;
    if (val) {
      fetch(val)
        .then(res => res.json())
        .then(pkg => {
          this.pkg = pkg;
        });
    }
  }

  constructor() {
    super();
    this.packageJsonPath = './package.json';
  }

  render() {
    // language=html
    return html`
      <div
        class="version"
        @click="${() => {
          this.showPopup = !this.showPopup;
        }}"
      >
        ${this.pkg.name}#${this.pkg.version} at ${this.pkg.versionDate}
      </div>
      <ki-popup .visible="${this.showPopup}"
        >place holder for change log</ki-popup
      >
    `;
  }
}
